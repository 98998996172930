import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import LeftNav from "./components/layout/LeftNav";
import Register from "./pages/Register";
import AdminPage from "./pages/AdminPage";
import AddTestimony from "./pages/AddTestimony";
function App() {
  return (
    <Router>
      <LeftNav />
      <main className="bg-gray-950 text-gray-200 flex">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/add-testimony" element={<AddTestimony />} />
          <Route path="/admin" element={<AdminPage />} />
          
        </Routes>
      </main>
    </Router>
  );
}

export default App;
