import { useEffect, useState } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetTestimonialQuery,
  useVerifyTestimoyMutation,
} from "../../services/authApi";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal2 from "../common/Modal2";
import Modal from "../common/Modal3";
import AddTestimony from "../../pages/AddTestimony";

const Testimony = () => {
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { data, isLoading } = useGetTestimonialQuery();

  const {
    userToken,
    userInfo,
    loading,
    error: backendError,
  } = useSelector((state) => state.auth);
  const [verifyTestimoy, { data: verifyData, isLoading: pending }] =
    useVerifyTestimoyMutation();
  const navigate = useNavigate();

  const handleApprove = async ({ id, data }) => {
    const response = await verifyTestimoy({ id, data });
  };
  const [testimony, setTestimony] = useState(null);

  useEffect(() => {
    const savedTestimony = localStorage.getItem("testimony");
    if (savedTestimony) {
      setTestimony(JSON.parse(savedTestimony));
    }
  }, []);

  if (isLoading) {
    return <div className=" text-center">Loading...</div>;
  }
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleAddTestimony = () => {
    setShowModal(true);
  };
  let testimonies = data;
  if (userInfo.userRole !== "admin") {
    testimonies = data?.filter(
      (item) => item.isVerified === true || userInfo.user === item.createdBy
    );
  }

  return (
    <section id="Testimony">
      <h1 className="text-3xl pt-4 font-semibold my-6 mt-12 uppercase">
        Testimonies
      </h1>
      <div className="h-1 w-11 bg-slate-600 text-transparent mb-20">-</div>
      <div className=" border relative border-gray-900 max-w-[90vw]  md:max-w-[80vw]  lg:max-w-[70vw]  py-8 mx-auto overflow-hidden lg:py-16 px-6 md:px-10 lg:px-20">
        <button
          onClick={handleAddTestimony}
          className="absolute -top-0 bg-gray-950  -right-0 flex space-x-2 text-gray-500 border border-gray-800 md:px-3 py-1 items-center rounded-full hover:text-gray-400 hover:border-gray-400 transition-all duration-1000"
        >
          <p>Add testimony</p> <BiMessageSquareAdd className="text-xl" />
        </button>

        {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <AddTestimony />{" "}
          </Modal>
        )}

        {isLoading ? (
          <p>loading ...</p>
        ) : (
          <Slider {...settings}>
            {testimonies
              ?.filter(
                (item) => item.isVerified || userInfo.userRole === "admin"
              )
              ?.map((item, index) => {
                const incomingDate = item.createdAt;
                const dateFormat = new Date(incomingDate);

                const options = {
                  month: "short",
              
                  year: "numeric",
                };
                const verbalDate = dateFormat.toLocaleDateString(
                  "en-US",
                  options
                );

                return (
                  <div key={index} className="rounded text-center">
                    {userInfo.userRole === "admin" && (
                      <button
                        onClick={() =>
                          handleApprove({ id: item._id, data: item.isVerified })
                        }
                        className={`${
                          item.isVerified ? "bg-green-400" : "bg-yellow-400"
                        } absolute text-black px-2 py-px rounded-full`}
                      >
                        {item.isVerified
                          ? "Verfied"
                          : pending
                          ? "verifying..."
                          : "verify"}
                      </button>
                    )}

                    <img
                      className="m-auto w-24 h-24 object-cover rounded-full"
                      src={item.image}
                    />

                    <div className="text-gray-300 py-8 uppercase">
                      <div className=" flex justify-center space-x-2">
                        <p>{item.name}</p>{" "}
                        <span className="text-gray-700">|</span>
                        <p className="text-gray-500">{verbalDate}</p>
                      </div>
                      <p className="text-xs lowercase text-gray-500">
                        {item?.email || ""}
                      </p>
                    </div>

                    <p className="text-gray-500">{item.description}</p>
                  </div>
                );
              })}

            {testimonies
              ?.filter(
                (item) =>
                  testimony &&
                  item?.email === testimony?.email &&
                  userInfo.userRole !== "admin"
              )
              ?.map((item, index) => {
                const incomingDate = item.createdAt;
                const dateFormat = new Date(incomingDate);

                const options = {
                  month: "short",
            
                  year: "numeric",
                };
                const verbalDate = dateFormat.toLocaleDateString(
                  "en-US",
                  options
                );

                return (
                  <div key={index} className="rounded text-center">
                    <img
                      className="m-auto w-24 h-24 object-cover rounded-full"
                      src={item.image}
                    />

                    {item.isVerified ? (
                      <p className="text-green-500">
                        Approved{" "}
                        <span className="text-xs text-gray-500">
                          This is only visible to you
                        </span>
                      </p>
                    ) : (
                      <p className="text-yellow-500">
                        Waiting for Jeba's approval{" "}
                        <span className="text-xs text-gray-500 block">
                          This is only visible to you
                        </span>
                      </p>
                    )}

                    <div className="text-gray-300 py-8 uppercase">
                      <div className=" flex justify-center space-x-2">
                        <p>{item.name}</p>{" "}
                        <span className="text-gray-700">|</span>
                        <p className="text-gray-500">{verbalDate}</p>
                      </div>
                      <p className="text-xs lowercase text-gray-500">
                        {item?.email || ""}
                      </p>
                    </div>

                    <p className="text-gray-500">{item.description}</p>
                  </div>
                );
              })}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default Testimony;
