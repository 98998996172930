import { useEffect, useState } from "react";
import {AiOutlineLoading3Quarters} from "react-icons/ai"
import { loginUser, registerUser } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
const Login = () => {
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [error,setError] = useState("")
  
    const dispatch = useDispatch();

    const {userInfo, loading,error:backendError } = useSelector((state) => state.auth);
  
    function validateForm(email,password,name) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const isValidEmail =  re.test(String(email).toLowerCase());
      const isValidPassword = password.length >= 6
  
      return isValidEmail && isValidPassword
    }
    
    const navigate = useNavigate()
  
    useEffect(() => {
      if (userInfo?.userRole ==="admin"){
         navigate('/admin')
      }
      else if (userInfo.userRole == "user") {navigate('/')}

    }, [navigate, userInfo])
  
  
    useEffect(()=>{
      if (backendError){
        setError(backendError)
      }
    },[backendError])
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      if (!validateForm(email,password)){
        setError("Enter valid email and password greater than 6 characters!")
      }else {
        dispatch(loginUser({ email, password}));
      }
    };
  

  return (
    <div className="lg:p-20 p-6 min-h-screen flex flex-col items-center py-auto justify-center w-full">
      <Link className="text-gray-500 hover:text-gray-100" to={"/"}>Back to home</Link>
      <div className="border p-4 lg:p-8 my-auto border-gray-900 rounded-lg w-full md:w-1/3">
        <h1 className="text-gray-400 font-semibold text-xl py-2 pb-2">
          Please Login to continue
        </h1>
        <div className="flex text-sm lg:space-x-2 text-gray-400 pb-8">
           
            <p>Do not have an accout?</p>
            <Link className="hover:text-gray-200 text-gray-300" to = "/register" >Register</Link>

        </div>

        <div className="border-t border-gray-900 pt-6">
          <div className=" text-gray-600 space-x-2 py-4  items-center">
            <input
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Email"
              type="text"
            />
          </div>

          <div className=" text-gray-600 space-x-2 py-4 items-center">
            <input
              onChange={(e)=>setPassword(e.target.value)}
              value={password}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Password"
              type="text"
            />
          </div>

          <button onClick={handleSubmit} className="py-2 flex items-center justify-center px-4 space-x-2 rounded text-center w-full bg-blue-500 mt-10">
            {loading ? <AiOutlineLoading3Quarters className="animate-spin"/>:"" }<p className="px-2">Login</p> 
          </button>

          
          <div className = {`${!error?"":"bg-red-500 p-2"}  opacity-70 text-white my-4`}>
                {error}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
