import { useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Modal from "../common/Modal";
import { useState } from "react";
import Modal2 from "../common/Modal2";

const LeftNav = () => {
  const [showModal, setShowModal] = useState(false);
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let user = null;
  let loggedIn = false;
  if (userInfo?.userName) {
    user = userInfo.userName;
  }
  if (userToken) {
    loggedIn = true;
  }

  const handleLogout = () => {
    dispatch(logout());
    setShowModal(false);
  };

  return (
    <nav className="fixed hidden inset-y-0 left-0 w-1/6 py-auto lg:flex flex-col justify-center text-gray-100 p-4 basis-0 lg:basis-1/3">
      <div className="absolute top-0 left-0 p-2">
        <img className="w-14" src="./img/logo.png" alt="" />
      </div>

      {loggedIn ? (
        <div className="flex items-center flex-wrap space-x-2 text-xs absolute top-0 uppercase mt-4">
          <p>{user}</p>

          <button onClick={() => setShowModal(true)}>Logout</button>
          {showModal && (
            <Modal2
              setOpenModal={setShowModal}
              handleFunction={handleLogout}
              data={{
                title: "Want logout ?",
                buttonText: "Logout",
                description: "Are you sure, do you want to logout?",
              }}
            />
          )}
          {userInfo.userRole === "admin" ? (
            <Link
              className="text-gray-400 border border-gray-500 hover:border-gray-300 px-2 p-1 rounded my-2"
              to={"/admin"}
            >
              Admin Dashboard
            </Link>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <ul className=" flex flex-col gap-4 border-r border-gray-800 p-4 pr-8 ">
        <ScrollLink
          offset={-200}
          activeStyle={{ borderColor: "#f1f5f9", color: "#f1f5f9" }}
          className="border border-gray-800 p-2 text-gray-500 px-5 rounded-full"
          smooth
          spy
          to="Home"
        >
          <Link to="/">Home</Link>
        </ScrollLink>

        <ScrollLink
          offset={-200}
          activeStyle={{ borderColor: "#f1f5f9", color: "#f1f5f9" }}
          className="border border-gray-800 p-2 text-gray-500 px-5 rounded-full"
          smooth
          spy
          to="About"
        >
          About
        </ScrollLink>

        <ScrollLink
          offset={-200}
          activeStyle={{ borderColor: "#f1f5f9", color: "#f1f5f9" }}
          className="border border-gray-800 p-2 text-gray-500 px-5 rounded-full"
          smooth
          spy
          to="projects"
        >
          Projects
        </ScrollLink>

        <ScrollLink
          offset={-200}
          activeStyle={{ borderColor: "#f1f5f9", color: "#f1f5f9" }}
          className="border border-gray-800 p-2 text-gray-500 px-5 rounded-full"
          smooth
          spy
          to="Testimony"
        >
          Testimony
        </ScrollLink>
      </ul>
    </nav>
  );
};

export default LeftNav;
