import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import { authApi } from "./services/authApi";
import { thirdParty } from "./services/thirdParty";

// import rootReducer from './reducers'

const store = configureStore({
  reducer: {
    auth: authSlice,
    [authApi.reducerPath]: authApi.reducer,
    [thirdParty.reducerPath]: thirdParty.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, thirdParty.middleware),
});

export default store;
