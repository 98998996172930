import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import {  registerUser } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { usePostTestimonyMutation } from "../services/authApi";
import { MdArrowBack } from "react-icons/md";
const AddTestimony = () => {
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [image, setImage] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postTestimony, { isLoading, error, data }] =
    usePostTestimonyMutation();
  const {
    userToken,
    loading,
    error: backendError,
  } = useSelector((state) => state.auth);

  const uploadImage = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    if (!name) {
      setErrorMessage("Name is required");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
    if (!email) {
      setErrorMessage("Email is required");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
    if (!description) {
      setErrorMessage("Testimony is required");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    e.preventDefault();
    const formData = new FormData();
    formData.append("description", description);
    formData.append("email", email);
    formData.append("name", name);
    if (image) {
      formData.append("image", image);
    }
    const response = await postTestimony(formData);

    if (!response?.error && response.data) {
      localStorage.setItem('testimony', JSON.stringify(response.data));
      setSuccessMessage("You have successfully submitted the form.");
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      if (response?.error?.data?.message) {
        setErrorMessage(response?.error?.data?.message);
      } else {
        setErrorMessage("Error occurred, Please try again later.");
      }
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  // todo

  return (
    <div id="AddTestimony" className="w-full ">
      <div className="">
        <Link to="/" className="pb-5">
          <MdArrowBack />
        </Link>
        <h1 className="text-gray-200 border-b border-gray-900 pb-4">
          Write your testimony
        </h1>
        <div>
          <div className=" text-gray-600 py-4  items-center">
            <input
              required
              placeholder="Enter your name..."
              className="p-2 w-full mb-3  text-gray-200 text-sm placeholder:text-gray-500 rounded outline-none bg-[#18212f] "
              onChange={(e) => setName(e.target.value)}
            />

            <input
              required
              placeholder="Enter your email..."
              className="p-2 w-full mb-3  text-gray-200 text-sm placeholder:text-gray-500 rounded outline-none bg-[#18212f] "
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />

            <textarea
              placeholder="Write the testimony here"
              className="p-2 w-full  text-gray-200 text-sm placeholder:text-gray-500 rounded outline-none bg-[#18212f] "
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              name=""
              id=""
              cols="10"
              rows="5"
            ></textarea>
            <div className="text-sm text-gray-400 pt-6">
              <label htmlFor="">Upload your photo (optional)</label>
              <input
                className=" bg-gray-600  text-gray-200 text-sm text-sm my-2 "
                onChange={uploadImage}
                type="file"
              />
            </div>
          </div>

          <button
            type="submit"
            onClick={handleSubmit}
            className="py-1 flex items-center justify-center px-4 space-x-2 rounded text-center w-full bg-blue-500 mt-4"
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              ""
            )}
            <p className="px-2">Add Testimony</p>
          </button>
        </div>
        <div>
          {successMessage ? (
            <p className="bg-green-400 p-2 my-3">{successMessage} </p>
          ) : (
            <></>
          )}
          {errorMessage ? (
            <p className="bg-red-500 p-2 my-3">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTestimony;
