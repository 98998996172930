import About from "../components/about/About";

import Projects from "../components/Projects/Projects";

import Testimony from "../components/testimony/Testimony";
import Footer from "../components/layout/Footer";
import Home from "../components/home/Home";

const Main = () => {
  return (
    <>
      <div className="lg:ml-56 bg-gray-950 mx-auto md:px-16">
        <div className=" px-4">
          <Home />
          <About />
          <Projects />
          <Testimony />
          <Footer />
        </div>
        {/* {profileList.isLoading == true || !profileList.profile ? (
            <div className="h-screen flex border-b items-center border-gray-900 justify-center">
              <div className=" rounded-md p-4 w-100">
                <div className="animate-pulse flex items-center space-x-6">
                  <div className="rounded-full bg-slate-700 h-36 w-36"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-4 w-100 bg-slate-700 rounded col-span-12"></div>
                      </div>
                      <div className="h-4 w-100 bg-slate-700 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
        
          )} */}
      </div>
    </>
  );
};

export default Main;
