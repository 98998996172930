import { useSelector } from "react-redux";
import SkillComponent from "./SkillComponent";
import EducationComponent from "./EducationComponent";
import WorkExperience from "./WorkExperience";
import { useGetProfileQuery } from "../../services/authApi";
import {
  useGetGithubProfileQuery,
  useGetLeetcodeProfileQuery,
} from "../../services/thirdParty";
import { SiGithub, SiLeetcode, SiUpwork } from "react-icons/si";
import { RiShieldStarFill } from "react-icons/ri";
import { BsCircleFill } from "react-icons/bs";

const About = () => {
  const { data: profileArray, isLoading: getIsloading,profileError } = useGetProfileQuery();
  const { data: githubData, isLoading: isGithubLoading } =
    useGetGithubProfileQuery();

  const {
    data: leetcodeData,
    error,
    isLoading: isLeetcodeLoading,
  } = useGetLeetcodeProfileQuery();

  console.log(profileArray, profileError, getIsloading)
  let profile = [];
  if (profileArray) {
    profile = profileArray[0];
  }
 

  return (
    <section
      className="lg:min-h-screen pb-10 lg:pb-20 flex flex-col text-gray-300"
      id="About"
    >
      <h1 className="text-3xl pt-4 font-semibold my-6 mt-12 uppercase">
        About me
      </h1>
      <div className="h-1 w-11 bg-slate-600 text-transparent">-</div>

      <div className="pb-4 md:pl-10">
        <div>
          <p className="text-gray-400 p-4 pl-8 border-l border-gray-700 my-12">
            {profile?.about}
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold my-20 mb-4 overflow-x-clip">Online Presence</h2>
          <div className="h-0.5 w-8 bg-slate-600 mb-10"></div>

          <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-8">
            {/* Github section */}
            {githubData && (
              <a
                href={githubData.html_url}
                target="_blank"
                rel="noreferrer"
                className="border flex border-gray-900 flex-1 p-4 rounded-lg shadow-lg"
              >
                <div className="flex justify-between w-full items-center space-x-4">
                  <div className="flex items-center space-x-3">
                    <a
                      href={githubData.html_url}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-gray-100"
                    >
                      <SiGithub size={44} />
                    </a>
                    <div>
                      <h2 className="text-xl ">Github</h2>{" "}
                      <p className="text-gray-500 text-xs">
                        Since {new Date(githubData.created_at).getFullYear()}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="text-sm text-end text-gray-300">
                      <p>{githubData.public_repos} Public Repos</p>
                      <p>{githubData.followers} Followers</p>
                    </div>
                  </div>
                </div>
              </a>
            )}

            {/* Upwork section */}
            <a
              href={
                "https://www.upwork.com/freelancers/~01f3131ce9639838b5?mp_source=share"
              }
              target="_blank"
              rel="noreferrer"
              className="border flex border-gray-900 flex-1 p-4 rounded-lg shadow-lg "
            >
              <div className=" items-center space-x-4 flex w-full justify-between">
                <div className="flex space-x-3">
                  <SiUpwork className="hover:text-gray-100" size={44} />
                  <div>
                    <h2 className="text-xl ">Upwork</h2>{" "}
                    <p className="text-gray-500 text-xs">100% Job success</p>
                  </div>
                </div>

                <div className="flex flex-col items-center text-sm">
                  <RiShieldStarFill color="blue" size={34} /> <h1>Top Rated</h1>
                </div>
              </div>
            </a>

            {/* Leetcode Section */}
            <a
              href={"https://leetcode.com/u/Jebdeju/"}
              target="_blank"
              rel="noreferrer"
              className="border flex border-gray-900 flex-1 p-4 rounded-lg shadow-lg "
            >
              <div className=" items-center space-x-4 flex w-full justify-between">
                <div className="flex space-x-3">
                  <SiLeetcode className="hover:text-gray-100" size={44} />
                  <div>
                    <h2 className="text-xl ">Leetcode</h2>{" "}
                    <p className="text-gray-500 text-xs">
                      Solved {leetcodeData?.solvedProblem} Problems
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-start text-xs text-gray-300">
                  <div className="flex items-center space-x-1">
                    <BsCircleFill className="text-green-400" /> <p>Easy</p>{" "}
                    <p>{leetcodeData?.easySolved}</p>{" "}
                  </div>
                  <div className="flex items-center space-x-1">
                    <BsCircleFill className="text-yellow-400" /> <p>Medium</p>{" "}
                    <p>{leetcodeData?.mediumSolved}</p>{" "}
                  </div>
                  <div className="flex items-center space-x-1">
                    <BsCircleFill className="text-red-400" /> <p>Hard</p>{" "}
                    <p>{leetcodeData?.hardSolved}</p>{" "}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold my-20 mb-4">My skills</h2>
          <div className="h-0.5 w-8 bg-slate-600  mb-10"></div>
          <SkillComponent
            title={"Languages"}
            skills={profile?.skills}
            skillType={1}
          />
          <SkillComponent
            title={"Libraries and Frameworks"}
            skills={profile?.skills}
            skillType={2}
          />
          <SkillComponent
            title={"Productivity Tools"}
            skills={profile?.skills}
            skillType={3}
          />
        </div>
        <div className="flex flex-col lg:flex-row  justify-between md:gap-4 lg:gap-8 divide-x divide-gray-900 md:mt-8 mt-5 lg:mt-16">
          <EducationComponent education={profile?.education} />
          <WorkExperience workExperience={profile?.workExperience} />
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default About;
