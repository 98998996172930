const WorkExperience = ({ workExperience }) => {
  return (
    <div className="pl-10 flex-1">
      <h2 className="text-xl font-semibold my-4 lg:mt-10 mt-4">Work Experiences</h2>
      <div className="h-0.5 w-8 bg-slate-600  mb-12"></div>

      {workExperience?.map((work, index) => {
        const startDateString = work.startDate;
        const endDateString = work.endDate;

        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        const options = { month: "short", year: "numeric" };
        const formatedStartDate = startDate.toLocaleDateString(
          "en-US",
          options
        );
        let formatedEndDate = endDate.toLocaleDateString("en-US", options);

        const now = new Date();

        if (endDate.getTime() > now.getTime()) {
          formatedEndDate = "Present";
        }

        return (
          <div key={index} className="mb-5 lg:mb-8 border-gray-700 py-2 ">
            <a
              href={work.link}
              target="_blank"
              className="text font-semibold text-gray-400 mb-2 hover:text-white"
            >
              {work.company}
            </a>
            <div className="flex text-gray-500 divide-x text-sm divide-gray-700">
              <p className="md:pr-3 pr-2 my-2">{work.position}</p>
              <p className="md:pl-3 pl-2 my-2">
                {formatedStartDate} - {formatedEndDate}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkExperience;
