import { icons } from "../utils/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalImage from "react-modal-image";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { SiGithub } from "react-icons/si";
import { BsLink45Deg } from "react-icons/bs";
import {
  useDeleteWorkMutation,
  useGetWorksQuery,
  useLikeWorkMutation,
} from "../../services/authApi";
import Modal2 from "../common/Modal2";

const Projects = () => {
  const [readMore, setReadMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [likeLoading, setLikeLoading] = useState(null);
  const [likeWork, { isLoading, isError, data, error }] = useLikeWorkMutation();
  const { userInfo, userToken } = useSelector((state) => state.auth);

  const [deleteWork] = useDeleteWorkMutation();
  const { data: works } = useGetWorksQuery();

  useEffect(() => {
    // Handle storage changes to update state if needed
    const handleStorageChange = (event) => {
      if (event.key && event.key.startsWith("likes_")) {
        // Force a re-render or state update here
        console.log(
          `Storage updated: ${event.key} changed to ${event.newValue}`
        );
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLike = async (id) => {
    try {
      const savedLikes = JSON.parse(localStorage.getItem(`likes_${id}`) || "0");
      const isLiked = JSON.parse(
        localStorage.getItem(`liked_${id}`) || "false"
      );

      // Toggle like status
      if (isLiked) {
        // Remove like
        const newLikes = savedLikes - 1;
        localStorage.setItem(`likes_${id}`, JSON.stringify(newLikes));
        localStorage.setItem(`liked_${id}`, JSON.stringify(false));
        await likeWork({ id, action: "unlike" }); // Example backend API call
      } else {
        // Add like
        const newLikes = savedLikes + 1;
        localStorage.setItem(`likes_${id}`, JSON.stringify(newLikes));
        localStorage.setItem(`liked_${id}`, JSON.stringify(true));
        await likeWork({ id, action: "like" }); // Example backend API call
      }
    } catch (error) {
      console.error("Error handling like", error);
    } finally {
      setLikeLoading(null);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteWork(id);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
    setShowDeleteModal(false);
  };

  if (!works) {
    return (
      <div className="min-h-screen text-center flex flex-col justify-center">
        Loading...
      </div>
    );
  }

  return (
    <section className="py-20 border-t border-gray-900" id="projects">
      <h1 className="text-3xl pt-4 font-semibold my-6 mt-12 uppercase">
        My Works
      </h1>
      <div className="h-1 w-11 bg-slate-600 text-transparent mb-20">-</div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 gap-y-8 md:gap-8">
        {works?.map((work) => {
          // Retrieve likes count from localStorage
          // const likeCount = JSON.parse(
          //   localStorage.getItem(`likes_${work._id}`) || "0"
          // );
          const isLiked = JSON.parse(
            localStorage.getItem(`liked_${work._id}`) || "false"
          );

          return (
            <div
              key={work._id}
              className="border border-gray-900 rounded relative"
            >
              {showDeleteModal && (
                <Modal2
                  setOpenModal={setShowModal}
                  handleFunction={() => handleDelete(deleteId)}
                  data={{
                    title: "Want Delete the work?",
                    buttonText: `Delete`,
                    description: "Are you sure, do you want to delete?",
                  }}
                />
              )}
              <ModalImage
                className="rounded h-44 w-full object-cover"
                small={work.image}
                large={work.image}
                alt={work.title}
              />

              {userInfo.userRole === "admin" && (
                <button
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteId(work._id);
                  }}
                  className="bg-red-500 absolute right-0 top-0 px-2 py-1 rounded"
                >
                  Delete
                </button>
              )}
              <div className="p-4 flex flex-col">
                <h4 className="text-gray-300 font-semibold mb-4">
                  {work.title}
                </h4>
                <div className="flex space-x-1 text-gray-500 uppercase items-center pb-4">
                  <p className="text-xs border-r pr-2 border-gray-800">
                    {work.field}
                  </p>
                  <div className="flex space-x-2 pl-2">
                    {work.tags?.map((tag, index) => {
                      const name = tag.toLowerCase();
                      return (
                        <div key={index}>
                          {icons[name] || <p className="text-xs">{name}</p>}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <p className="text-gray-500 text-sm border-t border-gray-900 pt-4">
                  {!readMore
                    ? work.description.slice(0, 140) + "..."
                    : work.description}
                  <button
                    onClick={() => setReadMore(!readMore)}
                    className="underline px-2 text-xs text-gray-400"
                  >
                    {readMore ? "Read Less" : "Read More"}
                  </button>
                </p>
                <div className="flex">
                  <button
                    onClick={() => {
                      handleLike(work._id);
                      setLikeLoading(null);
                    }}
                    className="mt-4 flex w-auto mr-auto rounded-lg h-8 px-2 items-center border text-gray-500 border-gray-900"
                  >
                    {likeLoading === work._id ? (
                      <AiOutlineLoading3Quarters className="animate-spin" />
                    ) : isLiked ? (
                      <HiStar className="text-yellow-500" />
                    ) : (
                      <HiOutlineStar />
                    )}
                    <p className="text-xs px-2">{work?.likes || 0}</p>
                  </button>

                  <div className="mt-4 flex rounded-lg h-8 space-x-3 items-center text-gray-500">
                    {work.links?.map((link, ind) => {
                      return (
                        <a
                          className="mx-2 hover:text-gray-300 border-gray-900"
                          href={link}
                          target="_blank"
                          key={ind}
                        >
                          {link.includes("github") ? (
                            <SiGithub />
                          ) : (
                            <BsLink45Deg />
                          )}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
