import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const thirdParty = createApi({
  reducerPath: "thirdParty",
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getGithubProfile: builder.query({
      query: () => ({
        url: "https://api.github.com/users/jebessad",
        method: "GET",
      }),
    }),
    getLeetcodeProfile: builder.query({
      query: () => ({
        url: "https://alfa-leetcode-api.onrender.com/jebdeju/solved",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetGithubProfileQuery, useGetLeetcodeProfileQuery } =
  thirdParty;
