import { AiFillHeart } from "react-icons/ai";
import {
  RiTelegramLine,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiFacebookCircleLine,
  RiMailSendLine,
} from "react-icons/ri";
import Modal2 from "../common/Modal2";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { MdAllInbox, MdMailOutline } from "react-icons/md";
import { SiGithub } from "react-icons/si";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let user = null;
  let loggedIn = false;
  if (userInfo?.userName) {
    user = userInfo.userName;
  }

  if (userToken) {
    loggedIn = true;
  }

  const handleLogout = () => {
    dispatch(logout());
    setShowModal(false);
  };
  return (
    <section
      className="h-screen flex flex-col border-b border-gray-900 justify-center"
      id="Home"
    >
      {loggedIn ? (
        <div className="flex z-10 w-full fixed top-0 lg:hidden items-center flex-wrap space-x-2 text-xs bg-gray-950 uppercase py-2">
          <p>{user}</p>

          <button onClick={() => setShowModal(true)}>Logout</button>
          {showModal && (
            <Modal2
              setOpenModal={setShowModal}
              handleFunction={handleLogout}
              data={{
                title: "Want logout ?",
                buttonText: "Logout",
                description: "Are you sure, do you want to logout?",
              }}
            />
          )}
          {userInfo.userRole === "admin" ? (
            <Link
              className="text-gray-400 py-2 z-10 border border-gray-500 hover:border-gray-300 px-2 p-1 rounded"
              to={"/admin"}
            >
              Admin Dashboard
            </Link>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="flex w-full flex-col md:flex-row items-center">
        <div className="flex flex-col items-center">
          {" "}
          <img
            className="w-24 h-24 md:w-36 my-10 md:my-0 md:h-36 hover:scale-105 duration-1000 rounded-full lg:mr-4"
            src="./img/avater.png"
            alt=""
          />
          <a
            className="text-gray-600 text-center text-sm mt-3 border px-2 rounded lg:mr-4 border-gray-600 hover:text-gray-400"
            href="jebessa-dejene.pdf"
            target="_blank"
          >
            Download cv
          </a>
        </div>

        <div className="pr-2 md:pr-10 lg:pr-20 border-l pl-2 md:pl-6 lg:pl-8 border-gray-900">
          <h1 className="text-4xl text-gray-200  pb-1">Jebessa Dejene</h1>
          <p className="text-gray-500 uppercase">Software Engineer</p>
        </div>
        <div className="md:ml-auto px-2 my-10 md:my-0 md:px-12 lg:px-24">
          <h2 className="text-xl hidden md:block text-end">Get In touch</h2>
          <div className="h-px hidden md:block my-2 mb-4 w-10 bg-slate-500 ml-auto"></div>
          <div className="mx-auto flex items-center text-gray-200 text-xl rounded-full p-1">
            <a
              href={"https://www.linkedin.com/in/jebessa-dejene-74a674221"}
              target="_blank"
              rel="noreferrer"
              className="mr-8 group"
            >
              <RiLinkedinBoxFill className="transition-all duration-[400ms] ease-out group-hover:-translate-y-1  group-hover:text-gray-100" />
            </a>

            <a
              href="mailto: jebessadejene2021@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="mr-8 group"
            >
              <RiMailSendLine className="transition-all duration-[400ms] ease-out group-hover:-translate-y-1  group-hover:text-gray-100" />
            </a>
            <a
              href={"https://www.github.com/jebessad/"}
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <SiGithub className="transition-all duration-[400ms] ease-out group-hover:-translate-y-1  group-hover:text-gray-100" />
            </a>
          </div>

          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Home;
