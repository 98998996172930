import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { usePostWorkMutation } from "../../services/authApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AddWork = () => {
  const [postWork, { data, error, isLoading }] = usePostWorkMutation();
  const [workData, setWorkData] = useState({
    title: "",
    field: "",
    tags: [],
    image: "",
    links: [],
    description: "",
    startDate: "",
    endDate: "",
  });
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      const file = files[0];
      setWorkData({
        ...workData,
        [name]: file,
      });
    } else if (name === "tags" || name === "links") {
      // Split the comma-separated values into an array
      const arrayValue = value.split(",")?.map((item) => item.trim());
      setWorkData({
        ...workData,
        [name]: arrayValue,
      });
    } else {
      setWorkData({
        ...workData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", workData.title);
    formData.append("field", workData.field);
    formData.append("image", workData.image);
    workData.tags.forEach((tag, index) => {
      formData.append(`tags[${index}]`, tag);
    });
  
    // Append each link individually
    workData.links.forEach((link, index) => {
      formData.append(`links[${index}]`, link);
    });
  
    formData.append("description", workData.description);
    formData.append("startDate", workData.startDate);
    formData.append("endDate", workData.endDate);

    try {
      const response = await postWork(formData).unwrap();
      // Do something with the response if needed
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="grid grid-cols-2 gap-4 my-3" onSubmit={handleSubmit}>
      <h1 className="col-span-2 text-2xl">Add work</h1>
      <div>
        <label htmlFor="title">Title:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="text"
          id="title"
          name="title"
          value={workData.title}
          onChange={handleChange}
          required
        />{" "}
      </div>

      <div>
        <label htmlFor="field">Field:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="text"
          id="field"
          name="field"
          value={workData.field}
          onChange={handleChange}
          required
        />{" "}
      </div>

      <div>
        <label htmlFor="tags">Tags:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="text"
          id="tags"
          name="tags"
          value={workData.tags}
          onChange={handleChange}
          required
        />{" "}
      </div>

      <div>
        <label htmlFor="image">Image:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="file"
          id="image"
          name="image"
          // value={workData.image}
          onChange={handleChange}
        />{" "}
      </div>

      <div>
        <label htmlFor="links">Links:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="text"
          id="links"
          name="links"
          value={workData.links}
          onChange={handleChange}
          required
        />{" "}
      </div>

      <div>
        <label htmlFor="description">Description:</label>
        <textarea
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          id="description"
          name="description"
          value={workData.description}
          onChange={handleChange}
          required
        />{" "}
      </div>

      <div>
        <label htmlFor="startDate">Start Date:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="date"
          id="startDate"
          name="startDate"
          value={workData.startDate}
          onChange={handleChange}
        />
      </div>

      <div>
        <label htmlFor="endDate">End Date:</label>
        <input
          className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
          type="date"
          id="endDate"
          name="endDate"
          value={workData.endDate}
          onChange={handleChange}
        />
      </div>
      <button
        type
        className="py-2 flex items-center justify-center px-4 space-x-2 rounded text-center w-full bg-blue-500 mt-4"
      >
        {isLoading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          ""
        )}
        <p className="px-2">AddTestimony</p>
      </button>
    </form>
  );
};

export default AddWork;
