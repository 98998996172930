import {
  SiPython,
  SiMongodb,
  SiReact,
  SiFlutter,
  SiJavascript,
  SiNodedotjs,
  SiTypescript,
  SiBootstrap,
  SiTailwindcss,
  SiFigma,
  SiGit,
  SiPostman,
  SiSpringboot,
  SiMui,
  SiRedux,
  SiAngular,
  SiCsharp,
  SiDart,
  SiKotlin,
  SiDotnet,
  SiDjango,
  SiUpwork,
  SiWhatsapp,
  SiGithub,
  SiLeetcode,
  SiFirebase,
  SiSupabase,
  SiNestjs,
  SiGraphql,
  SiJest,
  SiCypress,
  SiNextdotjs,
  SiPostgresql,
  SiPrisma,
} from "react-icons/si";

export const icons = {
  python: <SiPython />,
  mongodb: <SiMongodb />,
  react: <SiReact />,
  flutter: <SiFlutter />,
  javascript: <SiJavascript />,
  node: <SiNodedotjs />,
  typescript: <SiTypescript />,
  bootstrap: <SiBootstrap />,
  tailwind: <SiTailwindcss />,
  figma: <SiFigma />,
  git: <SiGit />,
  postman: <SiPostman />,
  springboot: <SiSpringboot />,
  materialui: <SiMui />,
  redux: <SiRedux />,
  angular: <SiAngular />,
  csharp: <SiCsharp />,
  dart: <SiDart />,
  kotlin: <SiKotlin />,
  dotnet: <SiDotnet />,
  django: <SiDjango />,
  upwork: <SiUpwork />,
  whatsapp: <SiWhatsapp />,
  github: <SiGithub />,
  leetcode: <SiLeetcode />,
  firebase: <SiFirebase />,
  supabase: <SiSupabase />,
  next: <SiNextdotjs />,
  nestjs: <SiNestjs />,
  graphql: <SiGraphql />,
  jest: <SiJest />,
  cypress: <SiCypress />,
  postgresql: <SiPostgresql />,
  prisma: <SiPrisma />,
};
