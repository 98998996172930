import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api/`,
    tagTypes: ["Work", "Testimony"],
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", "Bearer " + token);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    likeWork: builder.mutation({
      query: ({ id, action }) => ({
        url: `works/${id}/like`,
        method: "POST",
        body: { id, action },
      }),
      invalidatesTags: ["Work"],
    }),

    getTestimonial: builder.query({
      query: () => ({
        url: "testimony",
        method: "GET",
      }),
      providesTags: ["Testimony"],
    }),
    getWorks: builder.query({
      query: () => ({
        url: "works",
        method: "GET",
      }),
      providesTags: ["Work"],
    }),
    deleteWork: builder.mutation({
      query: (id) => ({
        url: `works/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Work"],
    }),
    postTestimony: builder.mutation({
      query: (data) => ({
        url: "testimony",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Testimony"],
    }),
    verifyTestimoy: builder.mutation({
      query: ({ id, data }) => ({
        url: `testimony/approve/${id}`,
        method: "PATCH",
        body: { isVerified: !data },
      }),
      invalidatesTags: ["Testimony"],
    }),
    postWork: builder.mutation({
      query: (data) => ({
        url: "works",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Work"],
    }),

    getProfile: builder.query({
      query: () => ({
        url: "profile",
        method: "GET",
      }),
    }),
    addProfile: builder.mutation({
      query: (data) => ({
        url: "profile",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useVerifyTestimoyMutation,
  useGetWorksQuery,
  useDeleteWorkMutation,
  usePostWorkMutation,
  useGetTestimonialQuery,
  useLikeWorkMutation,
  usePostTestimonyMutation,
  useAddProfileMutation,
  useGetProfileQuery,
} = authApi;
