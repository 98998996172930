const EducationComponent = ({ education }) => {
  return (
    <div className=" border-l flex-1 lg:border-l-0 border-gray-900 pl-10 lg:pl-0">
      <h2 className="text-xl font-semibold my-10 mb-4">Educations</h2>
      <div className="h-0.5 w-8 bg-slate-600  mb-12"></div>

      {education?.map((edu, index) => {
        const startDateString = edu.startDate;
        const endDateString = edu.endDate;

        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        const options = { month: "short",  year: "numeric" };
        const formatedStartDate = startDate.toLocaleDateString(
          "en-US",
          options
        );
        let formatedEndDate = endDate.toLocaleDateString("en-US", options);

        const now = new Date();

        if (endDate.getTime() > now.getTime()) {
          formatedEndDate = "Present";
        }

        return (
          <div key={index} className="mb-10 border-gray-700 py-2 ">
            <h4 className="text font-semibold text-gray-400 mb-2">
              {edu.title}
            </h4>
            <div className="flex text-gray-500 divide-x text-sm divide-gray-700">
              <p className="md:pr-3 pr-2">{edu.school}</p>
              <p className="md:pl-3 pl-2">
                {formatedStartDate} - {formatedEndDate}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EducationComponent;
