import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { registerUser } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const {
    loading,
    success,
    error: backendError,
  } = useSelector((state) => state.auth);


  function validateForm(email, password, name) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reName = /^[A-Za-z'-\s]{1,50}$/; // Matches letters, hyphens, apostrophes, and spaces, up to 50 characters
    const isValidName = reName.test(String(name).trim());
    const isValidEmail = re.test(String(email).toLowerCase());
    const isValidPassword = password.length >= 6;

    return isValidEmail && isValidPassword && isValidName;
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (success) navigate("/login");
  }, [navigate, success]);

  useEffect(() => {
    if (backendError) {
      setError(backendError);
    }
  }, [backendError]);

  useEffect(() => {
    if (password && password2 && password !== password2) {
      setError("Unmatched password");
    } else {
      setError("");
    }
  }, [password, password2]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm(email, password, name)) {
      setError(
        "Enter valid email and name with a password greater than 6 characters!"
      );
    } else {
      dispatch(registerUser({ email, password, name }));
    }
  };

  return (
    <div className="lg:p-20 p-6 min-h-screen flex flex-col items-center py-auto justify-center w-full">
      <Link className="text-gray-500 hover:text-gray-100" to={"/"}>
        Back to home
      </Link>

      <div className="border p-4 lg:p-8 my-auto border-gray-900 rounded-lg w-full md:w-1/3">
        <h1 className="text-gray-400 font-semibold text-xl py-2 pb-2">
          Please Register to continue
        </h1>

        <div className="flex text-sm space-x-2 text-gray-400 pb-8">
          <p>Already have an accout?</p>
          <Link className="hover:text-gray-200 text-gray-300" to="/login">
            Login
          </Link>
        </div>

        <div className="border-t border-gray-900 pt-6">
          <div className=" text-gray-600 space-x-2 py-4  items-center">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Name"
              type="text"
            />
          </div>

          <div className=" text-gray-600 space-x-2 py-4  items-center">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Email"
              type="text"
            />
          </div>

          <div className=" text-gray-600 space-x-2 py-4 items-center">
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Password"
              type="text"
            />
          </div>
          <div className=" text-gray-600 space-x-2 py-4 items-center">
            <input
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
              className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
              placeholder="Confirm password"
              type="text"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="py-2 flex items-center justify-center px-4 space-x-2 rounded text-center w-full bg-blue-500 mt-10"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              ""
            )}
            <p className="px-2">Register</p>
          </button>

          <div
            className={`${
              !error ? "" : "bg-red-900 p-2 px-4"
            } text-xm text-gray-300 my-4`}
          >
            {error}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
