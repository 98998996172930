import { icons } from "../utils/icons";

const SkillComponent = ({ title, skills, skillType }) => {
  const isLoaded = title && skills && skillType;

  if (!isLoaded) {
    return <div>Loading skills</div>;
  }

  return (
    <div className="mb-16  border border-gray-900 p-4 lg:p-10">
      <h4 className="text font-semibold text-gray-400 mb-8">{title}</h4>
      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-3 md:gap-5 lg:gap-8 content-end text-gray-300 border-l pl-4 border-gray-700 hover:text-white">
        {skills?.map((skill, index) => {
          if (skill?.skillType === skillType) {
            const name = skill.name.toLowerCase();
            const formatedName =
              skill.name.toUpperCase()[0] + skill.name.slice(1);
            return (
              <div key={index} className="flex flex-col items-center relative">
                <div className="text-3xl relative group">
                  {icons[name] || <p className="text-xl uppercase">{name}</p>}
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 translate-y-2 bg-gray-900 text-white text-sm py-1 px-2 rounded-md opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                    {formatedName}
                  </div>
                </div>
                <p className="text-sm text-gray-500 pt-6">
                  {skill?.proficiency}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SkillComponent;
