const Footer = () => {
  return (
    <div className="w-full py-3 mt-36 flex justify-center border-gray-900 border-t ">
      <p className="text-gray-400">
        Thank You for Visiting  |  Addis Ababa, Ethiopia
      </p>
    </div>
  );
};

export default Footer;
