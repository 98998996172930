import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdAdd, MdFileDownloadDone } from "react-icons/md";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useAddProfileMutation, useGetProfileQuery } from "../services/authApi";
import AddWork from "../components/admin/PostWorks";

const AdminPage = () => {
  const [addProfile, { isLoading, error, data }] = useAddProfileMutation();
  const { data: initialProfile, isLoading: getIsloading } =
    useGetProfileQuery();

  const [submitError, setSubmitError] = useState("");
  const [sumbitSuccess, setSumbitSuccess] = useState("");

  const [formProfile, setFormProfile] = useState({
    name: "",
    about: "",
    workExperience: [],
    education: [],
    cv: "",
    skills: [],
    links: [],
  });

  useEffect(() => {
    if (initialProfile) {
      const profileDataObj = { ...initialProfile[0] };
      const updatedFormProfile = {
        name: profileDataObj.name || "",
        about: profileDataObj.about || "",
        workExperience: [...profileDataObj.workExperience] || [],
        education: [...profileDataObj.education] || [],
        cv: profileDataObj.cv || "",
        skills: [...profileDataObj.skills] || [],
        links: [...profileDataObj.links] || [],
      };

      setFormProfile({ ...updatedFormProfile });
    }
  }, [initialProfile]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append the fields only if they have a value
    if (formProfile.name) {
      formData.append("name", formProfile.name);
    }

    if (formProfile.about) {
      formData.append("about", formProfile.about);
    }

    // Append workExperience data
    formProfile.workExperience.forEach((experience, index) => {
      if (
        experience.company ||
        experience.position ||
        experience.startDate ||
        experience.endDate ||
        experience.link
      ) {
        formData.append(`workExperience[${index}][company]`, [
          ...experience.company,
        ]);
        formData.append(`workExperience[${index}][position]`, [
          ...experience.position,
        ]);
        formData.append(
          `workExperience[${index}][startDate]`,
          experience.startDate
        );
        formData.append(
          `workExperience[${index}][endDate]`,
          experience.endDate
        );
        formData.append(`workExperience[${index}][link]`, [...experience.link]);
      }
    });

    // Append education data
    formProfile.education.forEach((edu, index) => {
      if (edu.school || edu.title || edu.startDate || edu.endDate) {
        formData.append(`education[${index}][school]`, edu.school);
        formData.append(`education[${index}][title]`, edu.title);
        formData.append(`education[${index}][startDate]`, edu.startDate);
        formData.append(`education[${index}][endDate]`, edu.endDate);
      }
    });

    // Append skills data
    formProfile.skills.forEach((skill, index) => {
      if (skill.name || skill.proficiency) {
        formData.append(`skills[${index}][skillType]`, skill.skillType);
        formData.append(`skills[${index}][name]`, skill.name);
        formData.append(`skills[${index}][proficiency]`, skill.proficiency);
      }
    });

    // Append links data
    formProfile.links.forEach((link, index) => {
      if (link.platform || link.link) {
        formData.append(`links[${index}][platform]`, link.platform);
        formData.append(`links[${index}][link]`, link.link);
      }
    });

    try {
      const response = await addProfile(formProfile);
      if (response?.data) {
        setSumbitSuccess("Updated successfully!");
      } else if (response?.error) {
        setSubmitError(response?.error?.message);
      } else {
        setSubmitError("Something went wrong");
      }

      setTimeout(() => {
        setSumbitSuccess("");
        setSubmitError("");
      }, 5000);

    } catch (error) {
      console.error(error, "catch");
      setSubmitError("Something went wrong");
      setTimeout(() => {
        setSubmitError("");
      }, 4000);
    }

  };

  const handleChange = (event) => {
    setFormProfile({
      ...formProfile,
      [event.target.name]: event.target.value,
    });
  };

  const handleWorkExperienceChange = (event, index) => {
    const { name, value } = event.target;
    const updatedWorkExperience = [...formProfile.workExperience];
    updatedWorkExperience[index] = {
      ...updatedWorkExperience[index],
      [name]: value,
    };

    setFormProfile({
      ...formProfile,
      workExperience: updatedWorkExperience,
    });
  };

  const handleEducationChange = (event, index) => {
    const { name, value } = event.target;
    const updatedEducation = [...formProfile.education];
    updatedEducation[index] = { ...updatedEducation[index], [name]: value };

    setFormProfile({
      ...formProfile,
      education: updatedEducation,
    });
  };

  const handleSkillChange = (event, index) => {
    const { name, value } = event.target;
    const updatedSkills = [...formProfile.skills];
    updatedSkills[index] = { ...updatedSkills[index], [name]: value };

    setFormProfile({
      ...formProfile,
      skills: updatedSkills,
    });
  };

  const handleLinkChange = (event, index) => {
    const { name, value } = event.target;
    const updatedLinks = [...formProfile.links];
    updatedLinks[index] = { ...updatedLinks[index], [name]: value };

    setFormProfile({
      ...formProfile,
      links: updatedLinks,
    });
  };

  const handleAddWorkExperience = () => {
    setFormProfile({
      ...formProfile,
      workExperience: [
        ...formProfile.workExperience,
        { company: "", position: "", startDate: "", endDate: "", link: "" },
      ],
    });
  };

  const handleAddEducation = () => {
    setFormProfile({
      ...formProfile,
      education: [
        ...formProfile.education,
        { school: "", title: "", startDate: "", endDate: "" },
      ],
    });
  };
  // const handleAddEducation = (event) => {
  //   const {value,name} = event.target
  //   const filtered = formProfile.filter()
  //   setFormProfile({
  //     ...formProfile,
  //   });
  // };

  const handleAddSkill = () => {
    setFormProfile({
      ...formProfile,
      skills: [
        ...formProfile.skills,
        { skillType: 0, name: "", proficiency: "" },
      ],
    });
  };

  const handleAddLink = () => {
    setFormProfile({
      ...formProfile,
      links: [...formProfile.links, { platform: "", link: "" }],
    });
  };

  const handleRemoveEducation = (index) => {
    const updatedEducation = [...formProfile.education];
    updatedEducation.splice(index, 1);
    setFormProfile({
      ...formProfile,
      education: updatedEducation,
    });
  };

  const handleRemoveSkills = (index) => {
    const updatedSkills = [...formProfile.skills];
    updatedSkills.splice(index, 1);
    setFormProfile({
      ...formProfile,
      skills: updatedSkills,
    });
  };

  const handleRemoveLinks = (index) => {
    const updatedLinks = [...formProfile.links];
    updatedLinks.splice(index, 1);
    setFormProfile({
      ...formProfile,
      links: updatedLinks,
    });
  };

  const handleRemoveWorkExperience = (index) => {
    const updatedWorkExperience = [...formProfile.workExperience];
    updatedWorkExperience.splice(index, 1);
    setFormProfile({
      ...formProfile,
      workExperience: updatedWorkExperience,
    });
  };

  const { userInfo } = useSelector((state) => state.auth);

  if (userInfo?.userRole !== "admin") {
    return (
      <div className="p-20 min-h-screen flex flex-col items-center justify-center w-full">
        <p className="text-2xl uppercase">Unauthenticated admin</p>
        <Link
          className="text-gray-400 hover:text-gray-200 my-4 underline-offset-1 underline"
          to={"/"}
        >
          Back to home
        </Link>
      </div>
    );
  }

  if (getIsloading) {
    return (
      <div className="p-20 min-h-screen flex flex-col items-center justify-center w-full">
        <p className="">Loading...</p>
      </div>
    );
  }

  return (
    <div className=" min-h-screen mx-auto w-2/3 p-10 pl-20">
      <h1 className="text-3xl uppercase text-center pb-8">Edit profile</h1>
      <form>
        <div className="flex flex-col space-y-3  border-gray-900 border p-4 my-5 rounded">
          {/* <div className="flex basis-1/2 items-center justify-between"> */}
          <label className="mr-3 text-gray-500" htmlFor="name">
            Name:
          </label>
          <input
            className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
            type="text"
            id="name"
            name="name"
            value={formProfile.name}
            onChange={handleChange}
          />

          <label className="mr-3 text-gray-500" htmlFor="about">
            About:
          </label>
          <textarea
            className="p-2  w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
            id="about"
            name="about"
            value={formProfile.about}
            onChange={handleChange}
          />
        </div>

        <h3 className="text-2xl text-white my-8">Work Experience:</h3>
        {formProfile.workExperience?.map((experience, index) => (
          <div
            className="grid grid-cols-2 gap-4 border-gray-900 border p-4 my-5 rounded"
            key={index}
          >
            <div>
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`company-${index}`}
              >
                Company:
              </label>
              <input
                className="my-1 p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`company-${index}`}
                name="company"
                value={experience.company}
                onChange={(event) => handleWorkExperienceChange(event, index)}
              />
            </div>
            <div>
              {" "}
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`position-${index}`}
              >
                Position:
              </label>
              <input
                className="my-1 p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`position-${index}`}
                name="position"
                value={experience.position}
                onChange={(event) => handleWorkExperienceChange(event, index)}
              />
            </div>

            <div>
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`startDate-${index}`}
              >
                Start Date:
              </label>
              <input
                className="my-1 p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="date"
                id={`startDate-${index}`}
                name="startDate"
                value={experience.startDate}
                onChange={(event) => handleWorkExperienceChange(event, index)}
              />
            </div>

            <div>
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`endDate-${index}`}
              >
                End Date:
              </label>
              <input
                className="my-1 p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="date"
                id={`endDate-${index}`}
                name="endDate"
                value={experience.endDate}
                onChange={(event) => handleWorkExperienceChange(event, index)}
              />
            </div>

            <div>
              <label className="mr-3 text-gray-500" htmlFor={`link-${index}`}>
                Link:
              </label>
              <input
                className="my-1 p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`link-${index}`}
                name="link"
                value={experience.link}
                onChange={(event) => handleWorkExperienceChange(event, index)}
              />
            </div>

            <button
              type="button"
              className="border col-span-2 px-2 py-1 justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-red-800 border-opacity-25 hover:border-opacity-95 rounded"
              onClick={handleRemoveWorkExperience}
            >
              Remove work experience
            </button>
          </div>
        ))}
        <div className="w-full flex">
          <button
            type="button"
            className="border flex items-center justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-gray-700 rounded p-1"
            onClick={handleAddWorkExperience}
          >
            <MdAdd className="text-2xl" />
            <p>Add Work Experience</p>
          </button>
        </div>

        <h3 className="text-2xl my-8 text-white">Education:</h3>
        {formProfile.education?.map((edu, index) => (
          <div
            className="grid grid-cols-2 gap-4 border-gray-900 border p-4 my-5 rounded"
            key={index}
          >
            <div>
              <label className="mr-3 text-gray-500" htmlFor={`school-${index}`}>
                School:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`school-${index}`}
                name="school"
                value={edu.school}
                onChange={(event) => handleEducationChange(event, index)}
              />
            </div>
            <div>
              <label className="mr-3 text-gray-500" htmlFor={`title-${index}`}>
                Title:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`title-${index}`}
                name="title"
                value={edu.title}
                onChange={(event) => handleEducationChange(event, index)}
              />
            </div>

            <div>
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`startDate-${index}`}
              >
                Start Date:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="date"
                id={`startDate-${index}`}
                name="startDate"
                value={edu.startDate}
                onChange={(event) => handleEducationChange(event, index)}
              />
            </div>
            <div>
              <label
                className="mr-3 my-2 text-gray-500"
                htmlFor={`endDate-${index}`}
              >
                End Date:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="date"
                id={`endDate-${index}`}
                name="endDate"
                value={edu.endDate}
                onChange={(event) => handleEducationChange(event, index)}
              />
            </div>
            <button
              type="button"
              className="border col-span-2 px-2 py-1 justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-red-800 border-opacity-25 hover:border-opacity-95 rounded"
              onClick={handleRemoveEducation}
            >
              Remove education
            </button>
          </div>
        ))}
        <button
          type="button"
          className="border flex items-center justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-gray-700 rounded p-1"
          onClick={handleAddEducation}
        >
          <MdAdd className="text-2xl" />
          <p>Add Education</p>
        </button>

        <h3 className="text-2xl my-4 text-white">Skills:</h3>
        {formProfile.skills?.map((skill, index) => (
          <div
            className="grid grid-cols-2  gap-4 border-gray-900 border p-4 my-5 rounded"
            key={index}
          >
            <div>
              <label
                className="mr-3 text-gray-500"
                htmlFor={`skillType-${index}`}
              >
                1: Language 2: Framework 3: Tools
              </label>
              <input
                placeholder="1 for language | 2 for framework | 3 for tools"
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="number"
                id={`skillType-${index}`}
                name="skillType"
                value={skill.skillType}
                onChange={(event) => handleSkillChange(event, index)}
              />
            </div>
            <div>
              <label className="mr-3 text-gray-500" htmlFor={`name-${index}`}>
                Skill Name:
              </label>
              <input
                placeholder="React"
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`name-${index}`}
                name="name"
                value={skill.name}
                onChange={(event) => handleSkillChange(event, index)}
              />
            </div>
            <div>
              <label
                className="mr-3 text-gray-500"
                htmlFor={`proficiency-${index}`}
              >
                Proficiency:
              </label>
              <input
                placeholder="Intermediate"
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`proficiency-${index}`}
                name="proficiency"
                value={skill.proficiency}
                onChange={(event) => handleSkillChange(event, index)}
              />
            </div>
            <button
              type="button"
              className="border col-span-2 px-2 py-1 justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-red-800 border-opacity-25 hover:border-opacity-95 rounded"
              onClick={handleRemoveSkills}
            >
              Remove Skills
            </button>
          </div>
        ))}
        <button
          type="button"
          className="border flex items-center justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-gray-700 rounded p-1"
          onClick={handleAddSkill}
        >
          <MdAdd className="text-2xl" />
          <p>Add Skill</p>
        </button>

        <h3 className="text-2xl my-4 text-white">Links:</h3>
        {formProfile.links?.map((link, index) => (
          <div className="grid grid-cols-2 gap-4" key={index}>
            <div>
              <label
                className="mr-3 text-gray-500"
                htmlFor={`platform-${index}`}
              >
                Platform:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`platform-${index}`}
                name="platform"
                value={link.platform}
                onChange={(event) => handleLinkChange(event, index)}
              />
            </div>

            <div>
              <label className="mr-3 text-gray-500" htmlFor={`link-${index}`}>
                Link:
              </label>
              <input
                className="p-2 w-full text-gray-400 placeholder:text-gray-700 rounded outline-none hover:bg-gray-800 focus:bg-gray-800 bg-gray-900"
                type="text"
                id={`link-${index}`}
                name="link"
                value={link.link}
                onChange={(event) => handleLinkChange(event, index)}
              />
            </div>
            
            <button
              type="button"
              className="border col-span-2 px-2 py-1 justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-red-800 border-opacity-25 hover:border-opacity-95 rounded"
              onClick={handleRemoveLinks}
            >
              Remove Link
            </button>
          </div>
        ))}

        <button
          type="button"
          className="border flex items-center my-2 justify-end ml-auto text-gray-500 hover:text-gray-300 space-x-2 border-gray-700 rounded p-1"
          onClick={handleAddLink}
        >
          <MdAdd className="text-2xl" />
          <p>Add Link</p>
        </button>
        <div className="w-full text-center flex"><button
          className="rounded px-auto w-full justify-center hover:bg-blue-600 bg-blue-500 p-2 my-4 flex items-center"
          onClick={handleSubmit}
          type="submit"
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            <></>
          )}
          <p className="px-3"> Submit</p>
        </button></div>
        
        <div>
          {submitError ? (
            <p className="text-red-500 py-4">{submitError}</p>
          ) : (
            <></>
          )}
          {sumbitSuccess ? (
            <p className="text-green-500 py-4">{sumbitSuccess}</p>
          ) : (
            <></>
          )}
        </div>
      </form>
      <div className="p-5 m-5">
        <AddWork />
      </div>
    </div>
  );
};

export default AdminPage;
